import { useDebugValue, useMemo } from 'react';
import Color from 'color';

function isDark(color) {
  return Color(color).isDark();
}
function useIsDark(color) {
  useDebugValue(color);
  return useMemo(function () {
    return isDark(color);
  }, [color]);
}

export { isDark, useIsDark };
