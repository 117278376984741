import { useState } from 'react';
import { useSealedState } from 'reakit-utils';

function usePlaygroundState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }

  var _useSealedState = useSealedState(initialState),
      _useSealedState$code = _useSealedState.code,
      initialCode = _useSealedState$code === void 0 ? "" : _useSealedState$code;

  var _React$useState = useState(initialCode),
      code = _React$useState[0],
      update = _React$useState[1];

  return {
    code: code,
    update: update
  };
}
var keys = ["code", "update"];
usePlaygroundState.__keys = keys;

export { usePlaygroundState };
